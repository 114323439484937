import React from 'react';
import Link from 'gatsby-link';
import Layout from '../../../components/layout'
import BodyClassName from 'react-body-classname';
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import "animate.css/animate.css";
import ScrollAnimation from 'react-animate-on-scroll';
import acomLogo from './ancestry-logo.svg'
import newDirection1 from './ancestry-new-directions1.png'
import newDirection2 from './ancestry-new-directions2.png'
import newDirection3 from './ancestry-new-directions3.png'
import newDirection4 from './ancestry-new-directions4.png'
import newDirection5 from './ancestry-new-directions5.png'
import newDirection6 from './ancestry-new-directions6.png'
import styled from '@emotion/styled'

const RED = styled.span`
  color: #c00;
`

const H4 = styled.h4`
 text-transform: uppercase;
 letter-spacing: .05em;
 color: #c00;
`
const Stack = styled.div`
  margin-top:40px;
  background-color: #efefef;
`

const CatTitle = styled.p`
font-size: .75em;
text-transform: uppercase;
color: #000;
opacity: .5;
letter-spacing: .1  em;
`

const H1 = styled.h1`
margin-top: 0;
`


export default () => (
  <Layout>
  <PageTransition>
  <BodyClassName className="">
  <div className="page">

  <article className="">

  <section className="contentSection">
    <div className="row">
      <div className="col-xs-9">
      <header>
      <Link to="./casestudies"><CatTitle>Case Study</CatTitle></Link>
        <H1>Concept Generation & Sketching</H1>
      </header>
      </div>
    </div>

    <div className="row">
      <div className="col-xs-12 col-sm-7">
        <H4>The Problem</H4>
        <p className="introText">
          Communicating concepts and ideas can often be challenging for individuals without drawing, visual design, or prototyping skills.
        </p>
        <H4>My Approach</H4>
        <p className="introText">
        Over my years at Ancestry and wherever I've worked, I have led and participated in the generation of hundreds of concepts for new products. Many ideas remain only as sketches, and many have made their way into the product.
        </p>
        <p className="introText">
          In addition to visualizing my own concept, I often put my sketching skills to work to assist in bringing ideas from engineers, product managers and others to life. Simple storyboard sketching can help amplify ideas as well as identify weaknesses in a concept.
        </p>
        <p>  
        I've included a few in this case study to showcase the early part of my design process.
        </p>
      </div>

      <div className="col-xs-12 col-sm-5">
        <ScrollAnimation animateIn="fadeIn" duration={.5}>
          <p><img alt="" src={acomLogo} style={{"maxWidth":"300px"}}/></p>          
          <ul>
            <li>Generated interface ideas & concepts for future products.</li>
            <li>Helped product managers & developers visualize their ideas as product concepts.</li>
            <li>Facilitated design thinking or brainstorm sessions to address specific product goals.</li>
          </ul>
        </ScrollAnimation>
      </div>
    </div>
    </section>

      <section className="contentSection">
  <h2 className="metalab-heading">A Lightweight App for Ancestry</h2>
      <div className="row">
        <div className="col-xs-12 col-sm-3">
<p>Over the years, added features have made Ancestry's app sizes large and unruly. This concept explored how we might simplify the Ancestry app for fast downloads and easier use.</p>
        </div>

      <div className="col-xs-12 col-sm-9">
        <ScrollAnimation animateIn="fadeIn" duration={.5}>
        <img alt=""  className="full" src={newDirection1} />
        </ScrollAnimation>
      </div>
</div>

      </section>



      <section className="contentSection">
  <h2 className="metalab-heading">Verified Trees</h2>
      <div className="row">
      <div className="col-xs-12 col-sm-3">
        <p>Ancestry has over 100 million trees in their database of varying quality. It's hard for customers to know which trees are reliable, and which may contain inaccurate data.</p>
        <p>This concept explored the idea of adding a verification step for members to assert the quality of their family trees.</p>
      </div>
      <div className="col-xs-12 col-sm-9 first-sm">

      <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
        <img alt=""  className="full" src={newDirection2}/>
      </ScrollAnimation>

      </div>

      </div>
      </section>


      <section className="contentSection">
  <h2 className="metalab-heading">Ancestry Ambassadors</h2>
      <div className="row">
      <div className="col-xs-12 col-sm-3">
        <p>To enable international business growth in both DNA and Family History. Ancestry needs to begin to engage people around the world.</p>
        <p>I explored concepts around how Ancestry might reach people in countries where they currently do not have any customers.</p>
      </div>
      <div className="col-xs-12 col-sm-9">

      <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
        <img alt=""  className="full" src={newDirection3}/>
      </ScrollAnimation>

      </div>

      </div>
      </section>

      <section className="contentSection">
  <h2 className="metalab-heading">Family Tree + DNA</h2>
      <div className="row">
      <div className="col-xs-12 col-sm-3">
        <p>To consolidate product experiences between DNA and Family History, we explored how an individual’s family tree information could be used to display a more enhanced understanding of their genetic origins.</p>
      </div>
      <div className="col-xs-12 col-sm-9 first-sm">

      <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
        <img alt=""  className="full" src={newDirection4}/>
      </ScrollAnimation>

      </div>

      </div>
      </section>

      <section className="contentSection">
  <h2 className="metalab-heading">Genetic Attributes</h2>
      <div className="row">
      <div className="col-xs-12 col-sm-3">
        <p>An interface to explore how genetic information about your ethnicity might relate to personal attributes. The concept explored combing data with historical data and fun facts to make the science more interesting.</p>
      </div>
      <div className="col-xs-12 col-sm-9">

      <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
        <img alt=""  className="full" src={newDirection5}/>
      </ScrollAnimation>

      </div>

      </div>
      </section>

            <section className="contentSection">
        <h2 className="metalab-heading">Cultural Explorer</h2>
            <div className="row">
            <div className="col-xs-12 col-sm-3">
              <p>For many customers, receiving their DNA test connects them to places, history, and times that they do not know. By including the ability to discover details about time and place easily, we could make Ancestry an even more compelling experience for customers.</p>
            </div>
            <div className="col-xs-12 col-sm-9 first-sm">

            <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
              <img alt=""  className="full" src={newDirection6}/>
            </ScrollAnimation>

            </div>

            </div>
            </section>



    </article>

  </div>

  </BodyClassName>
  </PageTransition>
  </Layout>

)
